import { Collection } from 'app/model';

import { Migration } from '.';

export const migrations: Record<number, Migration> = {
    1: {
        name: 'Move habits to a prefixed collection',
        async apply(db) {
            const docs = await db.allDocs({ include_docs: true });

            const mapped = docs.rows
                .filter((r) => r.id !== 'migration_meta' && !/.+:.+/.test(r.id))
                .map((r) => [
                    {
                        ...r.doc,
                        _id: `${Collection.HabitsV1}:${r.doc?._id}`,
                        _rev: undefined,
                    },
                ])
                .flat();

            await db.bulkDocs(mapped);
        },
    },
    2: {
        name: 'Define a sort order for the habit model',
        async apply(db) {
            const v1Habits = await db.allDocs({
                include_docs: true,
                startkey: `${Collection.HabitsV1}:`,
                endkey: `${Collection.HabitsV1}:\ufff0`,
            });

            const mapped = v1Habits.rows
                .map((r) => [
                    {
                        ...r.doc,
                        _id: `${Collection.HabitsV2}:${r.doc?._id.replace(
                            `${Collection.HabitsV1}:`,
                            ''
                        )}`,
                        _rev: undefined,
                        // Add new property
                        displayOrder: 0,
                    },
                ])
                .flat();

            await db.bulkDocs(mapped);
        },
    },
};
