import React from 'react';

interface Option<T> {
    label: string;
    value: T;
}

interface ButtonRadioProps<T> {
    options: Option<T>[];
    value: T;
    onChange: (option: Option<T>) => void;
}

export default function ButtonRadio<T>({
    options,
    value,
    onChange,
}: ButtonRadioProps<T>) {
    return (
        <span className="btn-group btn-group-block">
            {options.map((o) => (
                <button
                    key={o.label}
                    className={`btn ${o.value === value ? 'btn-primary' : ''}`}
                    onClick={() => {
                        onChange(o);
                    }}
                >
                    {o.label}
                </button>
            ))}
        </span>
    );
}
