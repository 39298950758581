import React, {
    PropsWithChildren,
    ReactElement,
    ReactFragment,
    ReactPortal,
    useState,
} from 'react';

interface ModalProps extends PropsWithChildren {
    open: boolean;
    title?: string;
    size?: 'sm' | 'lg';
    onCloseRequested: () => void;
}

function Header({ children }: PropsWithChildren) {
    return <div className="modal-header">{children}</div>;
}

function CloseButton() {
    return <a href="#close" className="modal-overlay" aria-label="Close"></a>;
}

function Body({ children }: PropsWithChildren) {
    return (
        <div className="modal-body">
            <div className="content">{children}</div>
        </div>
    );
}

function Footer({ children }: PropsWithChildren) {
    return <div className="modal-footer">{children}</div>;
}

function Modal({ open, title, children, onCloseRequested, size }: ModalProps) {
    // const subComponentList = Object.keys(Modal);
    // const subComponents = subComponentList.map((type) =>
    //     React.Children.map(children, (child) =>
    //         child?.type.name === type ? child : null
    //     )
    // );
    // React.Children.toArray(children)
    //     .filter((c): c is ReactElement => c !== undefined && (c as any).type !== undefined)
    //     .filter(c => Object.keys(Modal).includes(c.type));

    if (!open) {
        return null;
    }

    return (
        <div
            className={`modal ${open ? 'active' : ''} ${
                size ? `modal-${size}` : ''
            }`}
        >
            <a
                href="#close"
                className="modal-overlay"
                aria-label="Close"
                onClick={onCloseRequested}
            ></a>
            <div className="modal-container" style={{ height: '100%' }}>
                <div className="modal-header">
                    <a
                        href="#close"
                        className="btn btn-clear float-right"
                        aria-label="Close"
                        onClick={onCloseRequested}
                    ></a>
                    {title && <div className="modal-title h5">{title}</div>}
                </div>
                {children}
            </div>
        </div>
    );
}

// Modal.Header = Header;
// Modal.CloseButton = CloseButton;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
