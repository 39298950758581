import { DateTime } from 'luxon';

import { Collection } from '../model';

export interface JournalEntry {
    date: string;
    cards: string[];
    thoughts?: string;
}

export function idForJournalEntry(date: DateTime): string {
    return `${Collection.JournalEntries}:${date.toISODate()}`;
}
