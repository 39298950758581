// import '@angular-package/spectre.css/spectre.scss';
import 'material-icons/iconfont/filled.css';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import './global.scss';

const app = document.getElementById('app');
const root = createRoot(app!);
root.render(<App />);
