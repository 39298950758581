import confetti from 'canvas-confetti';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, {
    CSSProperties,
    PropsWithChildren,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useLongPress } from 'use-long-press';

import { Habit, Target } from 'app/model';
import {
    currentStreak,
    repsForDay,
    targetDailyRepsMet,
    targetMetInWindowPreceding,
} from 'app/model/questions';
import { isWeekendDay, pluralize } from 'app/utils';

import { DailyPunchCard } from './CalendarView';
import './HabitCard.scss';

function TargetSummaryLabel({ target }: { target: Target }) {
    const reps =
        target.repsPerDay === 1
            ? ''
            : `${pluralize('rep', target.repsPerDay)} `;

    const soManyDays =
        target.cycleDayReps === 1 ? '' : `${target.cycleDayReps} times `;

    let dayLabel = `every ${
        target.cycleLengthDays === 1
            ? 'day'
            : pluralize('day', target.cycleLengthDays)
    }`;

    if (target.cycleLengthDays % 7 === 0) {
        const weeks = target.cycleLengthDays / 7;
        dayLabel = `every ${weeks === 1 ? 'week' : pluralize('week', weeks)}`;
    }

    return <>{`${reps}${soManyDays}${dayLabel}`}</>;
}

function Expander({
    children,
    style,
}: PropsWithChildren<{ style?: CSSProperties }>) {
    const [expanded, setExpanded] = useState(false);

    return (
        <div>
            <div
                style={style}
                className="divider text-center"
                data-content={expanded ? 'Hide Progress ▲' : 'Show Progress ▼'}
                onClick={() => setExpanded(!expanded)}
            ></div>
            {expanded && children}
        </div>
    );
}

const localStyles: Record<string, CSSProperties> = {
    expander: {
        marginTop: '1.5em',
        cursor: 'pointer',
        fontVariant: 'all-small-caps',
    },
    cursorPointer: { cursor: 'pointer' },
};

export interface DateWithTargetInfo {
    date: DateTime;
    reps: number;
    targetMetForDay: boolean;
    targetMetInWindowPreceding: boolean;
}

export default function HabitCard({
    observer,
    habit,
    dates,
    onPunched,
    onDeleted,
}: {
    habit: Habit;
    dates: DateTime[];
    onPunched: (habit: Habit, date: DateTime, reset?: boolean) => void;
    onDeleted: (habit: PouchDB.Core.ExistingDocument<Habit>) => void;
    observer: IntersectionObserver | null;
}) {
    const cardRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!cardRef.current) {
            console.warn('no ref');
            return;
        }

        observer?.observe(cardRef.current);

        return () => {
            if (!cardRef.current) {
                console.warn('Ref is null');
                return;
            }

            observer?.unobserve(cardRef.current);
        };
    }, []);

    const datesWithTargetInfo = useMemo(() => {
        console.info(
            `Mounting HabitCard for ${habit.name} and projecting date window`
        );

        const ds = dates.map((date) => ({
            date,
            reps: repsForDay(date, habit),
            targetMetForDay: targetDailyRepsMet(date, habit),
            targetMetInWindowPreceding: targetMetInWindowPreceding(date, habit),
        }));

        return ds;
    }, [habit]);

    const streakLength = useMemo(
        () => currentStreak(dates[dates.length - 1], habit),
        [habit]
    );

    return (
        <div className="card my-2" ref={cardRef}>
            <div className="card-header">
                <div className="card-title h5">
                    <span className="float-left">{habit.name}</span>

                    <span className="float-right">
                        <span
                            className="material-icons text-gray"
                            style={localStyles.cursorPointer}
                            onClick={() => onDeleted(habit as any)}
                        >
                            delete
                        </span>
                    </span>
                    <div className="clearfix" />
                </div>
                <div className="card-subtitle text-gray">
                    <TargetSummaryLabel target={habit.target} />
                    <span className="text-primary float-right">
                        Cycles in a row: {streakLength}!
                    </span>
                </div>
                {/* <div className="bar bar-sm">
                    <div
                        className="bar-item"
                        role="progressbar"
                        style={{ width: '25%' }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div> */}
            </div>
            <div className="card-body">
                <div className="columns weekday-buttons">
                    {datesWithTargetInfo.map((day, idx) => {
                        const longPressEvents = useLongPress(
                            () => {
                                onPunched(habit, day.date, true);
                            },
                            {
                                threshold: 800,
                                cancelOnMovement: true,
                            }
                        );

                        const isWeekend = isWeekendDay(day.date);

                        return (
                            <div key={day.date.toMillis()}>
                                <div
                                    className={clsx('text-small text-center', {
                                        'text-gray':
                                            idx < dates.length - 1 &&
                                            !isWeekend,
                                        'bg-gray-light': isWeekend,
                                    })}
                                >
                                    {day.date.toLocaleString({
                                        month: 'short',
                                        day: 'numeric',
                                    })}
                                </div>
                                <figure
                                    style={localStyles.cursorPointer}
                                    className={`avatar avatar-lg punchcard-day-button mt-1 ${
                                        day.reps > 0 ? 'badge' : ''
                                    }`}
                                    data-badge={day.reps > 1 ? day.reps : null}
                                    data-initial={day.date.toLocaleString({
                                        weekday: 'narrow',
                                    })}
                                    onClick={(e) => {
                                        confetti({
                                            particleCount: 24,
                                            ticks: 100,
                                            scalar: 0.8,
                                            startVelocity: 20,
                                            // shapes: ['star'],`
                                            origin: {
                                                x: e.pageX / window.innerWidth,
                                                y: e.pageY / window.innerHeight,
                                            },
                                        });
                                        onPunched(habit, day.date);
                                    }}
                                    {...longPressEvents()}
                                >
                                    <i
                                        className={`avatar-presence ${
                                            day.targetMetForDay
                                                ? 'online'
                                                : day.targetMetInWindowPreceding
                                                ? 'away'
                                                : 'offline'
                                        }`}
                                    />
                                    {/* <i className="material-icons avatar-icon s-circle">
                                    done
                                </i> */}
                                </figure>
                            </div>
                        );
                    })}
                </div>
                <Expander style={localStyles.expander}>
                    <DailyPunchCard habit={habit} className="fade-in" />
                </Expander>
            </div>
        </div>
    );
}
