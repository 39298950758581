import { DateTime } from 'luxon';
import React, { CSSProperties, useMemo, useState } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';

import { Habit } from 'app/model';
import {
    targetDailyRepsMet,
    targetMetInWindowPreceding,
} from 'app/model/questions';
import { dateWindow } from 'app/utils';

function Box(props: any) {
    return (
        <div
            style={{
                background: props.color,
                width: props.big ? 7 : 30,
                height: props.big ? 7 : 30,
                margin: 1,
                borderRadius: '100%',
                lineHeight: '30px',
                textAlign: 'center',
                color: `rgba(255,255,255,${props.textAlpha})`,
            }}
            {...props.fp}
        >
            {!props.big && props.id}
        </div>
    );
}

interface DailyPunchCardProps {
    habit: Habit;
    className?: string;
}

const localStyles: Record<string, CSSProperties> = {
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        // gridTemplateRows: 'repeat(5, 1fr)',
        // gridAutoFlow: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        height: 175,
    },
    bigGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(30, 1fr)',
        // gridTemplateRows: 'repeat(5, 1fr)',
        // gridAutoFlow: 'column',
        justifyItems: 'center',
        // Height has to evenly divide by the number of rows
        // drawn when using center alignment to prevent graphical
        // glitches on iPhone after animation
        alignItems: 'center',
        height: 175,
    },
    monthHeader: {
        position: 'absolute',
        top: 0,
        transform: 'translateY(-100%)',
        fontSize: '0.7em',
    },
};

export const DailyPunchCard = React.memo(function DailyPunchCard({
    habit,
    className,
}: DailyPunchCardProps) {
    const now = DateTime.now();
    const [big, setBig] = useState(false);

    const dateTargetInfo = useMemo(() => {
        console.info(
            `Mounting DailyPunchCard for ${habit.name} and projecting date window`
        );

        // Compute range for whole 6mo history view + next week for calendar view
        const dates = dateWindow(187, now.plus({ days: 7 })).map((date) => ({
            date,
            targetMetForDay: targetDailyRepsMet(date, habit),
            targetMetInWindowPreceding: targetMetInWindowPreceding(date, habit),
        }));

        return dates;
    }, [habit]);

    const datas = big ? dateTargetInfo : dateTargetInfo.slice(-35);

    const containerStyle = big ? localStyles.bigGrid : localStyles.grid;

    // console.log(`Redrawing for habit ${habit.name}`);

    return (
        <Flipper flipKey={big}>
            <div
                className={className}
                style={containerStyle}
                onClick={() => setBig(!big)}
            >
                {datas.map((d) => {
                    return (
                        <Flipped flipId={d.date.ordinal} key={d.date.ordinal}>
                            {(fp) => (
                                <div className="relative-parent">
                                    {big && d.date.day === 1 && (
                                        <span
                                            className="text-gray"
                                            style={localStyles.monthHeader}
                                        >
                                            {d.date.monthShort}
                                        </span>
                                    )}
                                    <Box
                                        fp={fp}
                                        id={big ? null : d.date.day}
                                        big={big}
                                        textAlpha={
                                            d.date.month == now.month
                                                ? 0.9
                                                : 0.6
                                        }
                                        color={
                                            d.targetMetForDay
                                                ? 'hsla(var(--s-success-color-h),var(--s-success-color-s),calc(var(--s-success-color-l) + var(--s-success-color-l-l)),var(--s-success-color-a))'
                                                : d.targetMetInWindowPreceding
                                                ? 'hsla(var(--s-warning-color-h),var(--s-warning-color-s),calc(var(--s-warning-color-l) + var(--s-warning-color-l-l)),var(--s-warning-color-a))'
                                                : 'hsla(var(--s-gray-color-h),var(--s-gray-color-s),calc(var(--s-gray-color-l) + var(--s-gray-color-l-l)),var(--s-gray-color-a))'
                                        }
                                    />
                                </div>
                            )}
                        </Flipped>
                    );
                })}
            </div>
        </Flipper>
    );
});
