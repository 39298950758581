import React, { PropsWithChildren, ReactNode } from 'react';
import {
    LinkProps,
    LocationHook,
    Router,
    RouterProps,
    Link as WouterLink,
    useLocation,
    useRoute,
    useRouter,
} from 'wouter';

export function Link(
    props: PropsWithChildren<
        LinkProps<LocationHook> & { activeClass?: string; className?: string }
    >
) {
    const [isActive] = useRoute(props.href ?? ''); // TODO should consider child routes...

    const className = `${props.className ?? ''} ${
        isActive ? props.activeClass ?? 'active' : ''
    }`.trim();

    return (
        <WouterLink {...props}>
            <a aria-current={isActive} className={className}>
                {props.children}
            </a>
        </WouterLink>
    );
    ('active');
}
