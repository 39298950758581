import React, {
    CSSProperties,
    ReactNode,
    forwardRef,
    useEffect,
    useState,
} from 'react';

const baseViewStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
    minWidth: 0,
};

const scrollViewStyle = { ...baseViewStyle, overflow: 'auto' };

type ViewOrientation = 'row' | 'column';

const viewStyles: Record<ViewOrientation, CSSProperties> = {
    column: baseViewStyle,
    row: { ...baseViewStyle, flexDirection: 'row' },
};

interface ViewProps {
    children: ReactNode;
    orientation?: ViewOrientation;
    style?: CSSProperties;
    flex?: string | number;
    // flexDesktop?: string | number;
    // flexMobile?: string | number;
    className?: string;
    id?: string;
}

export function View({
    children,
    orientation = 'column',
    style = {},
    flex = 1,
    // flexDesktop,
    // flexMobile,
    className = '',
    id,
}: ViewProps) {
    // const windowSize = useWindowSize();

    // if ((windowSize.width ?? 0) < 576) {
    //     flex = flexMobile ?? flex;
    // } else {
    //     flex = flexDesktop ?? flex;
    // }

    return (
        <div
            style={{ ...viewStyles[orientation], ...style, flex }}
            className={className}
            id={id}
        >
            {children}
        </div>
    );
}

export const ScrollView = forwardRef<HTMLDivElement, ViewProps>(
    function ScrollView(props: ViewProps, ref) {
        return (
            <div
                style={scrollViewStyle}
                className={props.className}
                id={props.id}
                ref={ref}
            >
                {props.children}
            </div>
        );
    }
);

export function Spacer() {
    return <div style={{ flex: 1 }} />;
}

// https://usehooks.com/useWindowSize/
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<{
        width?: number;
        height?: number;
    }>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}
