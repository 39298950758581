import { DateTime } from 'luxon';

export const sum = (a: number, b: number) => a + b;

const cardinalPluralRules = new Intl.PluralRules();

export const pluralize = (singularWord: string, n: number) =>
    `${n} ${singularWord}${cardinalPluralRules.select(n) === 'one' ? '' : 's'}`;

// https://stackoverflow.com/a/75259983
const textEncoder = new TextEncoder();
export const encodeUtf8HexString = (input: string) => {
    const bytes = textEncoder.encode(input);

    return Array.from(bytes)
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
};

export function dateWindow(
    length: number = 7,
    relativeTo?: DateTime
): DateTime[] {
    relativeTo = relativeTo || DateTime.now();
    const start = relativeTo.minus({ days: length - 1 });

    const out = new Array(length)
        .fill(0)
        .map((v, i) => start.plus({ days: i }));
    return out;
}

// Extracted from https://github.com/unicode-org/cldr-json/blob/c9fd288b87082cc49ba424dcf65b06e11c72b940/cldr-json/cldr-core/supplemental/weekData.json#L212
const weekends: Record<string, number[]> = {
    AF: [4, 5],
    BH: [5, 6],
    DZ: [5, 6],
    EG: [5, 6],
    IL: [5, 6],
    IN: [7],
    IQ: [5, 6],
    IR: [4, 5],
    JO: [5, 6],
    KW: [5, 6],
    LY: [5, 6],
    OM: [5, 6],
    QA: [5, 6],
    SA: [5, 6],
    SD: [5, 6],
    SY: [5, 6],
    UG: [7],
    YE: [5, 6],
};

const localeCache: Record<string, Intl.Locale> = {};

export function isWeekendDay(date: DateTime): boolean {
    const locale = (localeCache[date.locale] ||= new Intl.Locale(date.locale));
    const region = locale.region;

    const weekend = weekends[region ?? ''] ?? [6, 7];
    return weekend.includes(date.weekday);
}
