import { DateTime, Interval } from 'luxon';

import { Habit, daysMetRepsPerDayInWindow } from '.';

export function repeatsDaily(habit: Habit): boolean {
    return habit.target.cycleLengthDays === 1;
}

export function targetMetInWindowPreceding(
    date: DateTime,
    habit: Habit
): boolean {
    // console.info(`computing targetMetInWindowPreceding for ${habit.name}`);
    const from = date.minus({
        days: habit.target.cycleLengthDays - 1,
    });

    const range = Interval.fromDateTimes(from, date);
    const reps = daysMetRepsPerDayInWindow(range, habit);

    return reps >= habit.target.cycleDayReps;
}

export function targetDailyRepsMet(day: DateTime, habit: Habit): boolean {
    // console.info(`computing targetDailyRepsMet for ${habit.name}`);
    const reps = repsForDay(day, habit);
    return reps >= habit.target.repsPerDay;
}

export function repsForDay(day: DateTime, habit: Habit): number {
    return habit.punchcards[day.year.toString()]?.points[day.ordinal - 1] ?? 0;
}

export function currentStreak(upTo: DateTime, habit: Habit): number {
    // TODO: doesn't show current progress from 'yesterday' for 'today' until you complete today
    if (!targetMetInWindowPreceding(upTo, habit)) {
        return 0;
    }

    // TODO: doesn't 'reset' if you complete a loop early
    let numWins = 0;
    while (targetMetInWindowPreceding(upTo, habit)) {
        numWins++;
        upTo = upTo.minus({ days: 1 });
    }

    return Math.ceil(numWins / habit.target.cycleLengthDays);
}
