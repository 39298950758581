import { Interval } from 'luxon';

import { sum } from 'app/utils';

export enum Collection {
    HabitsV1 = 'habits_v1',
    HabitsV2 = 'habits_v2',
    JournalEntries = 'journal_entries_v1',
}

export const CurrentHabitsCollection = Collection.HabitsV2;

type Year = string;

export interface Punchcard {
    yearOfIssue: Year;
    points: Uint8Array;
}

export interface Target {
    repsPerDay: number;
    cycleDayReps: number;
    cycleLengthDays: number;
}

export interface Habit {
    name: string;
    target: Target;
    punchcards: { [key: Year]: Punchcard | undefined };
    displayOrder: number;
}

export function idForHabit(habit: Habit): string {
    return `${CurrentHabitsCollection}:${habit.name.toLowerCase()}`;
}

export function newHabit(
    name: string,
    repsPerDay: number,
    daysMetDailyReps: number,
    cycleLengthDays: number
): Habit {
    return {
        name,
        target: {
            repsPerDay,
            cycleDayReps: daysMetDailyReps,
            cycleLengthDays,
        },
        punchcards: {},
        displayOrder: 0,
    };
}

export function newPunchcard(year: Year): Punchcard {
    return {
        yearOfIssue: year,
        points: new Uint8Array(366),
    };
}

export function daysMetRepsPerDayInWindow(
    range: Interval,
    habit: Habit
): number {
    const fromIdx = range.start.ordinal - 1;
    const endIdx = range.end.ordinal - 1;

    const years = new Array(range.end.year - range.start.year + 1)
        .fill(0)
        .map((v, i) => (range.start.year + i).toString());

    const totalReps = years
        .map((y, i) => {
            const from = i === 0 ? fromIdx : 0;
            const to = i === years.length - 1 ? endIdx + 1 : undefined;

            return (
                habit.punchcards[y]?.points.slice(from, to) ?? new Uint8Array()
            );
        })
        .map((pointsInRange) =>
            pointsInRange
                .filter((p) => p >= habit.target.repsPerDay)
                .map((p) => 1)
                .reduce(sum, 0)
        )
        .reduce(sum, 0);

    return totalReps;
}
