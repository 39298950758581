import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { AuthCtx } from 'app/auth';
import { ScrollView, View } from 'app/core/layout';
import { Collection } from 'app/model';
import { JournalEntry, idForJournalEntry } from 'app/model/journal';
import { useDocsFrom, useReplicatedPouchUserDb } from 'app/pouch';

import { ThoughtsModal } from './ThoughtsModal';

const sunset = new URL('./angelsunsetshish.jpg', import.meta.url);

//-- CARDS ----------------------------
const CARDS = [
    'Abundance',
    'Acceptance',
    'Adventure',
    'Angel Hugs',
    'Angel Joy',
    'Angel Laughter',
    'Angel Smiles',
    'Angel Wings',
    'Awe',
    'Beauty',
    'Birth',
    'Boldness',
    'Breath',
    'Charity',
    'Holy Light',
    'Clarity',
    'Communication',
    'Compassion',
    'Courage',
    'Creation',
    'Creative Play',
    'Creativity',
    'Dancing',
    'Delight',
    'Deliciousness',
    'Divine Breath',
    'Explore the Divine Feminine',
    'Divine Light',
    'Divine Love',
    'Explore the Divine Masculine',
    'Divine Purpose',
    'Education',
    'Efficiency',
    'Embracing  Change',
    'Empowerment',
    'Enjoyment',
    'Enlightenment',
    'Enthusiasm',
    'Expectancy',
    'Faith',
    'Faithfulness',
    'Fascination',
    'Flexibility',
    'Fly Free',
    'Forgiveness',
    'Let Go',
    'Free Spirit',
    'Freedom',
    'Excellence',
    'Fun',
    'Gentleness',
    'Grace',
    'Gracefulness',
    'Harmony',
    'Having  Fun',
    'Healing Light',
    'Healing',
    'Honesty',
    'Honouring Self',
    'Humour',
    'Illumination',
    'Inner Child',
    'Inner Light',
    'Inner strength',
    'Insight',
    'Inspiration',
    'Integration',
    'Integrity',
    'Joy',
    'Joyfulness',
    'Laughter',
    'Learning',
    'Lightness',
    'Love',
    'Manifestation',
    'Mercy',
    'Miracle',
    'Belief',
    'Mystery',
    'Obedience',
    'Oneness',
    'Openness',
    'Patience',
    'Peace',
    'Perception',
    'Perspective',
    'Playing',
    'Prayer',
    'Processing',
    'Purification',
    'Purity',
    'Release',
    'Determination',
    'Released',
    'Responsibility',
    'Revelation',
    'Self-acceptance',
    'Self-Appreciation',
    'Self-Worth',
    'Simplicity',
    'Hear our Angel Song',
    'Spiritual Learning',
    'Spiritual Power',
    'Spontaneity',
    'Stillness',
    'Support',
    'Surrender',
    'Synchronicity',
    'Tenderness',
    'Transformation',
    'Trust in the Divine',
    'Trust',
    'Understanding',
    'Unity',
    'Valuing',
    'Willingness',
    'Wonder',
    'Gratitude',
    'Thankfulness',
    'Spirituality',
    'Perseverance',
    'Self-healing',
    'Comfort',
    'Union',
    'Limitless Possibility',
    ' Practicality',
    'Knowing Source',
    'Unconditional Love',
    'Recognising Illusion',
    'Releasing Illusion',
    'Angel Blessings',
    'Blessing',
    'Sacred Fire',
    'Change',
    'Divine Wisdom',
    'Angelic  Protection',
    'Angel Heart',
    'Intuition',
    'Skills',
    'Potential',
    'Heart-thinking',
    'Non-Attachment',
    'Passion',
];
//-------------------------------------

function make_angel_card() {
    const rn = Math.round(Math.random() * 134);
    return card(rn);
}
function card(num: number): string {
    if (num >= 0 && num <= CARDS.length - 1) {
        // document.getElementById('cardarea').innerHTML +=
        //     '<i>Your Angel card is:</i> <b>' + cards[cnum] + '</b><br />';
        return CARDS[num];
    } else {
        return make_angel_card();
    }
}

function CardChips({ cards }: { cards: string[] }) {
    return (
        <View orientation="row" style={{ flexWrap: 'wrap' }}>
            {cards.map((c, i) => (
                <span
                    className="label label-accent fade-in"
                    key={i}
                    style={{
                        marginRight: 6,
                        marginBottom: 4,
                        whiteSpace: 'nowrap',
                    }}
                >
                    {c}
                </span>
            ))}
        </View>
    );
}

export default function JournalView() {
    const { authedUser } = useContext(AuthCtx);

    if (!authedUser) {
        return <h1>No user...</h1>;
    }

    const today = useMemo(() => DateTime.now(), []);

    const [currentEntry, setCurrentEntry] = useState<
        PouchDB.Core.Document<JournalEntry>
    >({
        _id: idForJournalEntry(today),
        date: today.toISODate(),
        cards: [],
    });

    const [userDb, syncHandle] = useReplicatedPouchUserDb(
        'habits',
        authedUser.user,
        authedUser.pass
    );

    const [loading, docs] = useDocsFrom<JournalEntry>(
        userDb,
        Collection.JournalEntries
    );

    useEffect(() => {
        const dbEntry = docs.find((d) => d._id === currentEntry._id);

        if (!dbEntry) {
            return;
        }

        setCurrentEntry(dbEntry);
    }, [docs]);

    const [addThoughts, setAddThoughts] = useState(false);
    const [thoughtContent, setThoughtContent] = useState('');

    const [showList, setShowList] = useState(false);

    return (
        <View style={{ position: 'relative' }}>
            <ScrollView>
                <main className="card" style={{ border: 'none' }}>
                    <div style={{ position: 'relative' }}>
                        <img
                            src={sunset.toString()}
                            style={{ aspectRatio: 1.34, width: '100%' }}
                        />
                        <div
                            className="card-header"
                            style={{
                                position: 'absolute',
                                top: 0,
                                color: 'white',
                            }}
                        >
                            <div className="card-title h3 mt-2">
                                {DateTime.fromISO(
                                    currentEntry.date
                                ).toLocaleString(
                                    DateTime.DATE_MED_WITH_WEEKDAY
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="card-header">
                        <div className="card-subtitle mt-1">
                            <CardChips cards={currentEntry.cards} />
                        </div>
                    </div>
                    <div className="card-body">
                        {currentEntry.thoughts && (
                            <>
                                <p
                                    style={{
                                        whiteSpace: 'pre-wrap',
                                        opacity: addThoughts ? 0 : 1,
                                        marginBottom: 80,
                                    }}
                                    onClick={() => {
                                        setThoughtContent(
                                            currentEntry.thoughts ?? ''
                                        );
                                        setAddThoughts(true);
                                    }}
                                >
                                    {currentEntry.thoughts}
                                </p>
                            </>
                        )}
                        {!currentEntry.thoughts && (
                            <div className="text-center m-4">
                                <button
                                    className="btn btn-link"
                                    onClick={() => setAddThoughts(true)}
                                >
                                    Add your thoughts...
                                </button>
                            </div>
                        )}
                    </div>
                </main>
            </ScrollView>
            <footer>
                <View
                    orientation="row"
                    style={{
                        justifyContent: 'space-around',
                        alignItems: 'end',
                        position: 'relative',
                        padding: 8,
                    }}
                    className="bg-secondary-dark"
                >
                    <button
                        className="btn btn-link"
                        onClick={() => setShowList(!showList)}
                    >
                        <span className="material-icons">list</span>
                    </button>
                    <button
                        className="btn btn-primary punchcard-day-button"
                        style={{
                            position: 'absolute',
                            width: 108,
                            height: 108,
                            borderRadius: '100%',
                            whiteSpace: 'break-spaces',
                            flexShrink: 0,
                            border: 'solid 2px #fff',
                            marginBottom: 4,
                        }}
                        onClick={() => {
                            const card = make_angel_card() as unknown as string;

                            userDb.put({
                                ...currentEntry,
                                cards: [...currentEntry.cards, card],
                            });
                        }}
                    >
                        {'Draw\nangel\ncard'}
                    </button>
                    <div style={{ width: 108 }} />
                    <button
                        className="btn btn-link"
                        onClick={() => {
                            if (
                                !confirm(
                                    'Are you sure you want to clear this entry?'
                                )
                            ) {
                                return;
                            }

                            userDb.put({
                                ...currentEntry,
                                cards: [],
                                thoughts: undefined,
                            });
                        }}
                    >
                        <span className="material-icons">restart_alt</span>
                    </button>
                </View>
            </footer>
            <ThoughtsModal
                value={thoughtContent}
                onChange={(val) => setThoughtContent(val)}
                onCancel={() => {
                    setAddThoughts(false);
                    setThoughtContent('');
                }}
                onSave={() => {
                    userDb.put({
                        ...currentEntry,
                        thoughts: thoughtContent.trim(),
                    });
                    setAddThoughts(false);
                    setThoughtContent('');
                }}
                open={addThoughts}
            />
            {showList && (
                <View
                    className="bg-secondary-dark fade-in"
                    style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                    }}
                >
                    <ScrollView className="p-4">
                        {docs
                            .slice(0)
                            .reverse()
                            .map((d) => (
                                <div
                                    key={d._id}
                                    className="card mt-2"
                                    onClick={() => {
                                        setCurrentEntry(d);
                                        setShowList(false);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="card-header">
                                        <div className="card-title h5">
                                            {DateTime.fromISO(
                                                d.date
                                            ).toLocaleString(
                                                DateTime.DATE_MED_WITH_WEEKDAY
                                            )}
                                        </div>
                                        <div className="card-subtitle mt-1">
                                            <CardChips cards={d.cards} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </ScrollView>
                    <footer>
                        <View
                            orientation="row"
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'end',
                                position: 'relative',
                                padding: 8,
                                borderTop: 'solid 1px rgba(0,0,0,0.1)',
                            }}
                            className="bg-secondary-dark"
                        >
                            <button
                                className="btn btn-link"
                                onClick={() => setShowList(!showList)}
                            >
                                Close
                            </button>
                            <button
                                className="btn btn-link"
                                onClick={() => {
                                    const todaysEntry = docs.find(
                                        (d) =>
                                            d._id === idForJournalEntry(today)
                                    ) ?? {
                                        _id: idForJournalEntry(today),
                                        date: DateTime.now().toISODate(),
                                        cards: [],
                                    };

                                    setCurrentEntry(todaysEntry);
                                    setShowList(!showList);
                                }}
                            >
                                Today
                            </button>
                        </View>
                    </footer>
                </View>
            )}
        </View>
    );
}
