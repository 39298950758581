import React from 'react';

interface NumberSpinnerProps {
    value: number;
    onChange: (val: number) => void;
    min?: number;
    max?: number;
    step?: number;
    suffixLabel?: string;
    disabled?: boolean;
    inputId?: string;
}

export default function NumberSpinner({
    value,
    onChange,
    min = 1,
    max = Infinity,
    step = 1,
    suffixLabel,
    disabled,
    inputId,
}: NumberSpinnerProps) {
    const clamped = (val: number) =>
        isNaN(val) ? min : Math.max(Math.min(val, max), min);
    return (
        <span className="input-group">
            <button
                disabled={disabled}
                className="btn input-group-btn"
                onClick={() => {
                    onChange(clamped(value + -1 * step));
                }}
            >
                <span className="material-icons">remove</span>
            </button>
            <input
                id={inputId}
                disabled={disabled}
                style={{ textAlign: 'center' }}
                className="form-input"
                inputMode="numeric"
                pattern="[0-9]*"
                value={value}
                onFocus={(e) => {
                    e.target.select();
                }}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(clamped(Number(e.target.value)));
                }}
            />
            <button
                disabled={disabled}
                className="btn input-group-btn"
                onClick={() => {
                    onChange(clamped(value + step));
                }}
            >
                <span className="material-icons">add</span>
            </button>
            {suffixLabel ? (
                <span className="input-group-addon">{suffixLabel}</span>
            ) : null}
        </span>
    );
}
