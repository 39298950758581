import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { View } from './layout';

interface ViewportModalProps {
    open: boolean;
}

export function ViewportModal({
    open,
    children,
}: PropsWithChildren<ViewportModalProps>) {
    const [viewport, setViewport] = useState({
        width: window.visualViewport?.width,
        height: window.visualViewport?.height,
    });

    const onResize = useCallback(() => {
        console.log('RESIZED');
        setViewport({
            width: window.visualViewport?.width,
            height: window.visualViewport?.height,
        });
    }, []);

    const onScroll = useCallback(() => {
        console.log('SCROLLED');
        setViewport({
            width: window.visualViewport?.width,
            height: window.visualViewport?.height,
        });
    }, []);

    useEffect(() => {
        window.visualViewport?.addEventListener('resize', onResize);
        window.visualViewport?.addEventListener('scroll', onScroll);

        return () => {
            window.visualViewport?.removeEventListener('resize', onResize);
            window.visualViewport?.removeEventListener('scroll', onScroll);
        };
    }, []);

    if (!open) {
        return null;
    }

    return (
        <View
            style={{
                zIndex: 1,
                position: 'fixed',
                top: 0,
                left: 0,
                // border: 'solid 2px red',
                width: viewport.width,
                height: viewport.height,
            }}
            className="fade-in"
        >
            {children}
        </View>
    );
}
