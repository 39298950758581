import { DateTime } from 'luxon';
import PouchDB from 'pouchdb';
import { useEffect, useMemo, useState } from 'react';

import { encodeUtf8HexString } from 'app/utils';

export function usePouchDb(name: string) {
    const db = useMemo(
        () => new PouchDB(name, { auto_compaction: true }),
        [name]
    );
    return db;
}

export function useReplicatedPouchUserDb(
    localDbName: string,
    remoteUser: string,
    remotePassword: string
): [
    localDb: PouchDB.Database,
    syncHandle: PouchDB.Replication.Sync<{}> | null
] {
    const localDb = usePouchDb(localDbName);

    const remoteDb = useMemo(() => {
        const remoteDbName = `userdb-${encodeUtf8HexString(remoteUser)}`;
        return new PouchDB(
            `https://${encodeURIComponent(remoteUser)}:${encodeURIComponent(
                remotePassword
            )}@couch.cgwyllie.xyz/${remoteDbName}`
        );
    }, [remoteUser, remotePassword]);

    const [syncHandle, setSyncHandle] =
        useState<PouchDB.Replication.Sync<{}> | null>(null);

    useEffect(() => {
        console.log('syncing...');
        const syncHandle = localDb.sync(remoteDb, {
            live: true,
            retry: true,
        });

        setSyncHandle(syncHandle);

        syncHandle.on('active', () => console.info(`Sync active`));
        syncHandle.on('change', () => console.info(`Sync ch ch ch changes`));
        syncHandle.on('complete', () => console.info(`Sync complete`));
        syncHandle.on('paused', (e) => console.warn(`Sync paused`, e));
        syncHandle.on('denied', () => console.error(`Sync denied!`));
        syncHandle.on('error', (e) => console.error(`Sync boom`, e));

        return () => {
            console.info('Cancelling sync...');
            syncHandle.cancel();
        };
    }, [localDb, remoteDb]);

    return [localDb, syncHandle];
}

export function useDocsFrom<T extends object>(
    db: PouchDB.Database,
    collection: string
): [loading: boolean, docs: PouchDB.Core.ExistingDocument<T>[]] {
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState<PouchDB.Core.ExistingDocument<T>[]>([]);

    const fetchData = async () => {
        setLoading(true);

        const opts: PouchDB.Core.AllDocsWithinRangeOptions = {
            include_docs: true,
            startkey: collection,
            endkey: `${collection}\ufff0`,
        };

        try {
            const result = await db.allDocs<T>(opts);
            setDocs(
                result.rows
                    .map((r) => r.doc)
                    .filter(
                        (d): d is PouchDB.Core.ExistingDocument<T> =>
                            d !== undefined
                    )
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();

        const subscription = db
            .changes({
                since: 'now',
                live: true,
            })
            .on('change', fetchData);

        return () => {
            subscription.cancel();
        };
    }, [db]);

    return [loading, docs];
}

interface MigrationMeta {
    currentVersion: number;
    migratedAt: string;
}

export interface Migration {
    readonly name: string;

    apply(db: PouchDB.Database): Promise<void>;
}

export async function migrate(
    db: PouchDB.Database,
    migrations: Record<number, Migration>
) {
    let meta: PouchDB.Core.Document<MigrationMeta> & { _rev?: string } = {
        _id: 'migration_meta',
        _rev: undefined,
        currentVersion: 0,
        migratedAt: DateTime.now().toISO(),
    };

    try {
        meta = await db.get<MigrationMeta>('migration_meta');
    } catch (e) {}

    const version = meta.currentVersion;
    const versions = Object.keys(migrations).map(Number).sort();
    const targetVersion = versions[versions.length - 1] || 0;

    if (version === targetVersion) {
        console.info('No migration necessary');
        return;
    }

    console.info(`Migrating from ${version} to ${targetVersion}`);

    for (let next = version + 1; next <= targetVersion; next++) {
        const migration = migrations[next];
        console.info(`Applying migration: ${migration.name}`);
        await migration.apply(db);
    }

    await db.put<MigrationMeta>({
        _id: 'migration_meta',
        _rev: meta._rev,
        currentVersion: targetVersion,
        migratedAt: DateTime.now().toISO(),
    });
}
