import React, { useContext, useEffect } from 'react';
import { Link } from 'wouter';

import { AuthCtx, clearPersistedAuth } from 'app/auth';
import { View } from 'app/core/layout';
import { usePouchDb } from 'app/pouch';

const logo = new URL('../icon-192x192.png', import.meta.url);

export function LogoutView() {
    const { setAuthedUser } = useContext(AuthCtx);
    const habitsDb = usePouchDb('habits');

    useEffect(() => {
        clearPersistedAuth();
        habitsDb.destroy();
        setAuthedUser(null);
    }, []);

    return (
        <View className="container text-center">
            <div className="text-center" style={{ margin: '20% 0 5% 0' }}>
                <img src={logo.toString()} width="192" height="192" />
            </div>
            <p>You've logged out ❤️</p>
            <p>
                <Link to="/login" replace>
                    Log in again
                </Link>
            </p>
        </View>
    );
}
