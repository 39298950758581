import React, { useEffect, useState } from 'react';

import ButtonRadio from 'app/core/ButtonRadio';
import Modal from 'app/core/Modal';
import NumberSpinner from 'app/core/NumberSpinner';
import { Habit, newHabit } from 'app/model';

enum HabitFormFreq {
    Daily,
    Weekly,
    Custom,
}

export default function AddHabitModal({
    open,
    onCloseRequested,
    onSubmit,
}: {
    open: boolean;
    onCloseRequested: () => void;
    onSubmit: (habit: Habit) => void;
}) {
    const [nameVal, setNameVal] = useState('');
    const [repsPerDayVal, setRepsPerDay] = useState(1);
    const [daysVal, setDays] = useState(1);
    const [cycleDays, setCycleDays] = useState(1);
    const [nameHasFocus, setNameFocused] = useState(false);
    const [cycleFreq, setCycleFreq] = useState(HabitFormFreq.Daily);

    useEffect(() => {
        setNameVal('');
        setRepsPerDay(1);
        setDays(1);
        setCycleDays(1);
        setNameFocused(true);
        setCycleFreq(HabitFormFreq.Daily);
    }, [open]);

    return (
        <Modal
            open={open}
            title="New Ostinato"
            onCloseRequested={onCloseRequested}
            size="lg"
        >
            <Modal.Body>
                <div className="form-group">
                    <label className="form-label" htmlFor="habit-name">
                        Name
                    </label>
                    <input
                        disabled={!nameHasFocus && nameVal.length > 0}
                        className="form-input"
                        type="text"
                        id="habit-name"
                        placeholder="What do you want to do?"
                        value={nameVal}
                        onChange={(e) => setNameVal(e.target.value ?? '')}
                        autoFocus
                        onFocus={() => setNameFocused(true)}
                        onBlur={() => setNameFocused(false)}
                    />
                </div>
                {!nameHasFocus && nameVal.length > 0 && (
                    <>
                        <div className="form-group">
                            <label className="form-label" htmlFor="habit-name">
                                Repetitions
                            </label>
                            <ButtonRadio<HabitFormFreq>
                                value={cycleFreq}
                                options={[
                                    {
                                        label: 'Daily',
                                        value: HabitFormFreq.Daily,
                                    },
                                    {
                                        label: 'Weekly',
                                        value: HabitFormFreq.Weekly,
                                    },
                                    {
                                        label: 'Custom',
                                        value: HabitFormFreq.Custom,
                                    },
                                ]}
                                onChange={(opt) => {
                                    setCycleFreq(opt.value);

                                    switch (opt.value) {
                                        case HabitFormFreq.Weekly:
                                            setCycleDays(7);
                                            setDays(1);
                                            setRepsPerDay(1);
                                            break;
                                        case HabitFormFreq.Daily:
                                            setCycleDays(1);
                                            // setRepsPerDay(1);
                                            setDays(1);
                                            break;
                                    }
                                }}
                            />
                        </div>
                        {cycleFreq === HabitFormFreq.Custom && (
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="habit-cycle"
                                >
                                    Cycle length (days)?
                                </label>
                                <NumberSpinner
                                    inputId="habit-cycle"
                                    value={cycleDays}
                                    max={200}
                                    onChange={(days) => {
                                        setCycleDays(days);

                                        if (days < daysVal) {
                                            setDays(1);
                                        }
                                    }}
                                />
                            </div>
                        )}
                        {(cycleFreq === HabitFormFreq.Weekly ||
                            cycleFreq === HabitFormFreq.Custom) && (
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="habit-days"
                                >
                                    How many days per{' '}
                                    {cycleFreq === HabitFormFreq.Weekly
                                        ? 'week'
                                        : `${cycleDays} day cycle`}
                                    ?
                                </label>
                                <NumberSpinner
                                    inputId="habit-days"
                                    disabled={cycleDays === 1}
                                    value={daysVal}
                                    max={cycleDays}
                                    onChange={(val) => {
                                        setDays(val);
                                    }}
                                />
                            </div>
                        )}
                        {(cycleFreq === HabitFormFreq.Daily ||
                            cycleFreq === HabitFormFreq.Custom) && (
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="habit-daily-reps"
                                >
                                    How many times per day?
                                </label>
                                <NumberSpinner
                                    inputId="habit-daily-reps"
                                    value={repsPerDayVal}
                                    onChange={(val) => setRepsPerDay(val)}
                                    max={200}
                                />
                            </div>
                        )}
                    </>
                )}
                {(nameHasFocus || !nameVal.length) && (
                    <button
                        className="btn btn-primary"
                        disabled={!nameVal.length}
                    >
                        Next
                    </button>
                )}
                {!nameHasFocus && nameVal.length > 0 && (
                    <button
                        className="btn btn-primary"
                        disabled={!nameVal.length}
                        onClick={() => {
                            const habit = newHabit(
                                nameVal,
                                repsPerDayVal,
                                daysVal,
                                cycleDays
                            );
                            onSubmit(habit);
                        }}
                    >
                        Add
                    </button>
                )}
            </Modal.Body>
            {/* <Modal.Footer>
                <button
                    className="btn btn-primary"
                    disabled={!nameVal.length}
                    onClick={() => {
                        const habit = newHabit(
                            nameVal,
                            repsPerDayVal,
                            daysVal,
                            cycleDays
                        );
                        onSubmit(habit);
                    }}
                >
                    Add
                </button>
            </Modal.Footer> */}
        </Modal>
    );
}
