import ParcelBundleInfo from '@cgwyllie/parcel-resolver-version';
import copy from 'clipboard-copy';
import React, { useContext, useEffect, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Route, RouteProps, useLocation, useRoute } from 'wouter';

import { AuthCtx, AuthedUser, readPersistedAuth } from './auth';
import { ScrollView, Spacer, View } from './core/layout';
import { Link } from './core/routing';
import { LoginView } from './screens/LoginView';
import { LogoutView } from './screens/LogoutView';
import JournalView from './screens/journal/JournalView';
import { OstinatoListView } from './screens/listview';

const logo = new URL('./icon-192x192.png', import.meta.url);

function AuthedRoute(props: RouteProps) {
    const { authedUser } = useContext(AuthCtx);
    const [, setLocation] = useLocation();
    const [active] = useRoute(props.path ?? '');

    useEffect(() => {
        if (!authedUser && active) {
            setLocation('/login', { replace: true });
        }
    }, [authedUser]);

    if (!authedUser) {
        return null;
    }

    return <Route {...props} />;
}

function Oops({ error }: FallbackProps) {
    const [showDeets, setShowDeets] = useState(false);
    const canShare = typeof navigator.share !== 'undefined';
    const shareText = btoa(
        JSON.stringify({
            err: error?.toString(),
            stack: error?.stack,
        })
    );

    return (
        <ScrollView className="p-2">
            <div className="text-center" style={{ margin: '20% 0 5% 0' }}>
                <img src={logo.toString()} width="192" height="192" />
            </div>

            <h1>Oh no! {showDeets ? '🙊' : '🙈'}</h1>

            <p>
                Terribly sorry but something has gone wrong! It would be great
                if you could share the details to help make Ostinato better ❤️
            </p>

            <button
                className="btn btn-link"
                onClick={() => setShowDeets(!showDeets)}
            >
                {!showDeets ? 'Show' : 'Hide'} the scary details{' '}
            </button>

            {showDeets && (
                <>
                    <h4 className="mt-2">{error?.toString()}</h4>
                    <pre className="code">
                        <code>{error?.stack}</code>
                    </pre>
                </>
            )}

            {canShare && (
                <button
                    className="btn btn-lg btn-primary mt-1"
                    onClick={() => {
                        navigator
                            .share({
                                title: 'Ostinato Error :-\\',
                                text: shareText,
                            })
                            .then(() => alert('Thanks for sharing!'))
                            .catch(() => {
                                alert('Failed to share the error...');
                            });
                    }}
                >
                    Share Details
                </button>
            )}
            {!canShare && (
                <button
                    className="btn btn-lg btn-primary mt-1"
                    onClick={() => {
                        copy(shareText).then(() =>
                            alert('Copied! Please share :)')
                        );
                    }}
                >
                    Copy Details to Share
                </button>
            )}
            <div style={{ display: 'flex', flexGrow: 1 }}></div>
            <button
                className="btn btn-sm btn-link mt-2"
                onClick={() => {
                    if (
                        confirm(
                            'Try to reload? The error might go away, but probably not...'
                        )
                    ) {
                        location.reload();
                    }
                }}
            >
                Try to reload in case the scary error goes away? 🙏
            </button>
        </ScrollView>
    );
}

export function App() {
    const [authedUser, setAuthedUser] = useState<AuthedUser | null>(null);

    useEffect(() => {
        const loggedInUser = readPersistedAuth();
        setAuthedUser(loggedInUser);
    }, []);

    return (
        <ErrorBoundary FallbackComponent={Oops}>
            <AuthCtx.Provider
                value={{
                    authedUser,
                    setAuthedUser,
                }}
            >
                <View>
                    <View>
                        <AuthedRoute path="/" component={OstinatoListView} />
                        <AuthedRoute path="/journal" component={JournalView} />
                        {/* <AuthedRoute path="/food">
                            <h1>Food</h1>
                        </AuthedRoute> */}
                        <AuthedRoute path="/me">
                            <View>
                                <header
                                    className="text-center"
                                    style={{ paddingTop: '15vh' }}
                                >
                                    <figure
                                        className="avatar avatar-xl"
                                        data-initial={authedUser?.user
                                            .substring(0, 1)
                                            .toLocaleUpperCase()}
                                    ></figure>
                                    <p className="mt-2">{authedUser?.user}</p>
                                </header>
                                <Spacer />
                                <footer className="text-center m-2">
                                    <Link to="/logout">Log out</Link>
                                    <div
                                        className="text-gray text-small m-1"
                                        onClick={() => {
                                            if (
                                                confirm(
                                                    'Would you like to reload to check for a new version?'
                                                )
                                            ) {
                                                top?.frames.location.reload();
                                            }
                                        }}
                                    >
                                        Version{' '}
                                        {ParcelBundleInfo.PACKAGE_VERSION} (
                                        {ParcelBundleInfo.GIT_REVISION})
                                    </div>
                                </footer>
                            </View>
                        </AuthedRoute>
                        <Route path="/login" component={LoginView} />
                        <Route path="/logout" component={LogoutView} />
                    </View>
                    {authedUser && (
                        <footer
                            className="navbar bg-bg-dark"
                            style={{
                                justifyContent: 'space-around',
                                paddingBottom: 'env(safe-area-inset-bottom)',
                            }}
                        >
                            <Link
                                href="/journal"
                                className="btn btn-link"
                                activeClass="btn-active"
                            >
                                <span className="material-icons">
                                    history_edu
                                </span>
                                Journal
                            </Link>
                            <Link
                                href="/"
                                className="btn btn-link"
                                activeClass="btn-active"
                            >
                                <span className="material-icons">
                                    all_inclusive
                                </span>
                                Habits
                            </Link>
                            {/* <Link
                                href="/food"
                                className="btn btn-link"
                                activeClass="btn-active"
                            >
                                <span className="material-icons">
                                    ramen_dining
                                </span>
                                Food
                            </Link> */}
                            <Link
                                href="/me"
                                className="btn btn-link"
                                activeClass="btn-active"
                            >
                                <span className="material-icons">
                                    account_circle
                                </span>
                                Account
                            </Link>
                        </footer>
                    )}
                </View>
            </AuthCtx.Provider>
        </ErrorBoundary>
    );
}
