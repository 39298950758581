import { produce } from 'immer';
import { DateTime } from 'luxon';

import { Habit, newPunchcard } from '.';

export function punchCard(
    habit: Habit,
    date: DateTime,
    occurrences: number = 1
): Habit {
    return produce(habit, (draft) => {
        const year = date.year.toString();
        const punchcard = draft.punchcards[year] ?? newPunchcard(year);

        const dayIdx = date.ordinal - 1;
        punchcard.points[dayIdx] = punchcard.points[dayIdx] + occurrences;

        draft.punchcards[year] = punchcard;
    });
}

export function resetCard(habit: Habit, date: DateTime): Habit {
    return produce(habit, (draft) => {
        const year = date.year.toString();
        const punchcard = draft.punchcards[year] ?? newPunchcard(year);

        const dayIdx = date.ordinal - 1;
        punchcard.points[dayIdx] = 0;

        draft.punchcards[year] = punchcard;
    });
}
