import {
    DndContext,
    DragOverlay,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    restrictToFirstScrollableAncestor,
    restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
    SortableContext,
    arrayMove,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, {
    CSSProperties,
    PropsWithChildren,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { Link } from 'wouter';

import { AuthCtx } from 'app/auth';
import { ScrollView, View } from 'app/core/layout';
import { CurrentHabitsCollection, Habit, idForHabit } from 'app/model';
import { punchCard, resetCard } from 'app/model/mutations';
import { migrate, useDocsFrom, useReplicatedPouchUserDb } from 'app/pouch';
import { migrations } from 'app/pouch/migrations';
import { dateWindow, pluralize } from 'app/utils';

import AddHabitModal from './AddHabitModal';
import HabitCard from './HabitCard';

function SortableItem({ id, children }: { id: string } & PropsWithChildren) {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={{ ...style, touchAction: 'manipulation' }}
            {...attributes}
            {...listeners}
        >
            {children}
        </div>
    );
}

function sortByDisplayOrder(a: Habit, b: Habit) {
    if (a.displayOrder < b.displayOrder) {
        return -1;
    } else if (a.displayOrder > b.displayOrder) {
        return 1;
    }

    return 0;
}

function sortByName(a: Habit, b: Habit) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
    }
    return 0;
}

function sortByCycleLength(a: Habit, b: Habit) {
    if (a.target.cycleLengthDays < b.target.cycleLengthDays) {
        return -1;
    }
    if (a.target.cycleLengthDays > b.target.cycleLengthDays) {
        return 1;
    }

    return 0;
}

function sortForListView(a: Habit, b: Habit) {
    return (
        [
            sortByDisplayOrder(a, b),
            sortByCycleLength(a, b),
            sortByName(a, b),
        ].find((o) => o !== 0) || 0
    );
}

// TODO: figure out Uint8Array conversions in storage.
//      IndexedDB backing store can hold UInt8Array, so it is transparently the correct type in local DB
//      However, it can't be written to JSON, so it gets encoded as an object {"0":0,"1":0,...}
//      If you sync the object form back from CouchDB, it needs manipulated back into a UInt8Array...
//      Implement serialisers? Once it's touched again locally, it's fine, it's just an issue on 'restore' of remote data.
function parserHack(
    habitsDb: PouchDB.Database
): [loading: boolean, docs: PouchDB.Core.ExistingDocument<Habit>[]] {
    const [loading, habits] = useDocsFrom<Habit>(
        habitsDb,
        CurrentHabitsCollection
    );

    const [parsedHabits, setParsedHabits] = useState<any[]>([]);

    useEffect(() => {
        habits.sort(sortForListView);

        setParsedHabits(
            habits.map((h) => {
                Object.keys(h.punchcards).forEach((year) => {
                    const card = h.punchcards[year];
                    console.log(card);

                    if (card && !(card.points instanceof Uint8Array)) {
                        console.log('converting', card.points);
                        card.points = Uint8Array.of(
                            ...(Object.values(card.points) as any)
                        );
                        console.log('converted', card.points);
                    }
                });

                return h;
            })
        );
    }, [habits]);

    return [loading, parsedHabits];
}

const localStyles: Record<string, CSSProperties> = {
    dragShadow: {
        boxShadow: '0px 3px 15px -4px rgba(0,0,0,0.6)',
    },
    moreFooter: {
        position: 'fixed',
        bottom: 'calc(72px + env(safe-area-inset-bottom))',
        fontVariant: 'all-small-caps',
        textAlign: 'center',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        paddingBottom: 8,
        borderWidth: 0,
    },
};

export function OstinatoListView() {
    const [adding, setAdding] = useState(false);
    const { authedUser } = useContext(AuthCtx);

    if (!authedUser) {
        return <h1>No user...</h1>;
    }

    const [habitsDb, syncHandle] = useReplicatedPouchUserDb(
        'habits',
        authedUser.user,
        authedUser.pass
    );

    useEffect(() => {
        syncHandle?.once('paused', async (e) => {
            console.info('Sync paused', e);

            if (e) {
                return;
            }

            console.info('Running migrations...');
            await migrate(habitsDb, migrations);
        });
        syncHandle?.on('error', (e) => alert((e as any).message));
    }, [syncHandle]);

    const [loading, dbHabits] = parserHack(habitsDb);
    const [habits, setHabits] = useState<
        PouchDB.Core.ExistingDocument<Habit>[]
    >([]);

    useEffect(() => {
        setHabits(dbHabits);
    }, [dbHabits]);

    const dates = dateWindow(); // FIXME: recompute in render, ugly
    const haveHabits = habits.length > 0;

    const scrollerRef = React.createRef<HTMLDivElement>();

    const [observer, setObserver] = useState<IntersectionObserver | null>(null);
    const [numNotVisible, setNumNotVisible] = useState(0);

    useLayoutEffect(() => {
        const offTheBottomEls = new WeakSet();
        let invisibleCount = 0;

        const observer = new IntersectionObserver(
            (entries) => {
                const prevInvisibleCount = invisibleCount;

                const visible = entries.filter((e) => e.isIntersecting);
                const offBottom = entries.filter(
                    (e) =>
                        !e.isIntersecting &&
                        e.boundingClientRect.top > (e.rootBounds?.bottom ?? 0)
                );

                visible.forEach((e) => {
                    if (offTheBottomEls.delete(e.target)) {
                        invisibleCount--;
                    }
                });

                offBottom.forEach((e) => {
                    if (offTheBottomEls.has(e.target)) {
                        return;
                    }

                    offTheBottomEls.add(e.target);
                    invisibleCount++;
                });

                // Can't compare against state value because it is captured into the closure as initial value
                if (invisibleCount !== prevInvisibleCount) {
                    setNumNotVisible(invisibleCount);
                }

                console.log(
                    `intersected something..., ${invisibleCount} NOT intersecting`
                );
            },
            {
                root: scrollerRef.current,
                rootMargin: '0px 0px -50px 0px',
            }
        );

        setObserver(observer);

        return () => {
            observer.disconnect();
        };
    }, []);

    const [activeDragItem, setActiveDragItem] = useState<Habit | null>(null);
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                delay: 1050, // TODO: make the header a drag handle, reduce delay to something sane...
                tolerance: 10,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 1050, // TODO: make the header a drag handle, reduce delay to something sane...
                tolerance: 10,
            },
        })
    );

    return (
        <View>
            <header className="navbar bg-primary text-light">
                <section className="navbar-section">
                    <span className="navbar-brand p-2">Ostinato</span>
                </section>
                {haveHabits && (
                    <section className="navbar-section">
                        <button
                            className="btn btn-sm btn-light mr-2"
                            onClick={() => setAdding(true)}
                        >
                            add
                        </button>
                    </section>
                )}
            </header>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={(e) => {
                    const activeHabit = habits.find(
                        (h) => h._id === e.active.id
                    );
                    if (!activeHabit) {
                        return;
                    }

                    setActiveDragItem(activeHabit);
                }}
                onDragEnd={async (e) => {
                    console.log(e);
                    setActiveDragItem(null);

                    if (e.over === null || e.active.id === e.over.id) {
                        console.info('No change in ordering');
                        return;
                    }

                    const from = habits.findIndex((h) => h._id === e.active.id);
                    const to = habits.findIndex((h) => h._id === e.over!.id);

                    const reordered = arrayMove(habits, from, to).map(
                        (h, i) => ({ ...h, displayOrder: i })
                    );

                    setHabits(reordered);
                    habitsDb.bulkDocs(reordered);
                }}
                modifiers={[
                    restrictToVerticalAxis,
                    restrictToFirstScrollableAncestor,
                ]}
            >
                <ScrollView ref={scrollerRef}>
                    <main className="container">
                        <SortableContext
                            items={habits.map((h) => h._id)}
                            strategy={verticalListSortingStrategy}
                        >
                            {habits.map((h) => (
                                <SortableItem key={h._id} id={h._id}>
                                    <HabitCard
                                        observer={observer}
                                        key={h._id}
                                        habit={h}
                                        dates={dates}
                                        onPunched={(
                                            habit,
                                            date,
                                            reset = false
                                        ) => {
                                            const updated = reset
                                                ? resetCard(habit, date)
                                                : punchCard(habit, date);
                                            habitsDb.put(updated);
                                        }}
                                        onDeleted={(habit) => {
                                            if (
                                                !confirm(
                                                    'Are you sure you want to delete this?'
                                                )
                                            ) {
                                                return;
                                            }

                                            habitsDb.remove(habit);
                                        }}
                                    />
                                </SortableItem>
                            ))}
                        </SortableContext>

                        {!loading && !haveHabits && (
                            <div className="empty my-2">
                                <div className="empty-icon">
                                    <i className="icon icon-people"></i>
                                </div>
                                <p className="empty-title h5">
                                    No Ostinatos yet...
                                </p>
                                <p className="empty-subtitle">
                                    Why not set something up? It's going to be
                                    great! 🙌
                                </p>
                                <div className="empty-action">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => setAdding(true)}
                                    >
                                        Get started!
                                    </button>
                                </div>
                            </div>
                        )}
                        <AddHabitModal
                            onSubmit={async (habit: Habit) => {
                                try {
                                    await habitsDb.put({
                                        _id: idForHabit(habit),
                                        ...habit,
                                    });
                                    setAdding(false);
                                } catch (e) {
                                    alert(
                                        'Problem saving habit, please try again!'
                                    );
                                }
                            }}
                            open={adding}
                            onCloseRequested={() => setAdding(false)}
                        />
                    </main>
                </ScrollView>
                <DragOverlay>
                    {activeDragItem !== null && (
                        <div style={localStyles.dragShadow}>
                            <HabitCard
                                habit={activeDragItem}
                                dates={dates}
                                onPunched={() => {
                                    /*noop*/
                                }}
                                onDeleted={() => {
                                    /*noop*/
                                }}
                                observer={null}
                            />
                        </div>
                    )}
                </DragOverlay>
            </DndContext>
            {numNotVisible > 0 && (
                <div
                    className="fade-in bg-secondary-dark toast"
                    style={localStyles.moreFooter}
                >
                    {`${pluralize('habit', numNotVisible)} below ☟`}
                </div>
            )}
        </View>
    );
}
