import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'wouter';

import { AuthCtx, persistAuth } from 'app/auth';
import { View } from 'app/core/layout';
import { encodeUtf8HexString } from 'app/utils';

const logo = new URL('../icon-192x192.png', import.meta.url);

export function LoginView() {
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [loading, setLoading] = useState(false);
    const { authedUser, setAuthedUser } = useContext(AuthCtx);
    const [, setLocation] = useLocation();

    useEffect(() => {
        if (authedUser) {
            persistAuth(authedUser);
            setLocation('/', { replace: true });
        }
    }, [authedUser]);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);

        const remoteDbName = `userdb-${encodeUtf8HexString(user)}`;
        const url = `https://couch.cgwyllie.xyz/${remoteDbName}`;
        const headers = {
            Authorization: `Basic ${btoa(`${user}:${pass}`)}`,
        };

        try {
            const res = await fetch(url, { headers });
            if (res.ok) {
                setAuthedUser({ user, pass });
            } else {
                console.warn(res);
                alert('Login failed...');
            }
        } catch (e) {
            alert(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <View className="container" style={{ maxWidth: 350 }}>
            <div className="text-center" style={{ margin: '20% 0 5% 0' }}>
                <img src={logo.toString()} width="192" height="192" />
            </div>

            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label className="form-label" htmlFor="user">
                        Username
                    </label>
                    <input
                        required
                        placeholder="Speak, Friend and enter..."
                        className="form-input"
                        type="text"
                        id="user"
                        value={user}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setUser(e.target.value)
                        }
                    />
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="pass">
                        Password
                    </label>
                    <input
                        required
                        placeholder="Keep it secret..."
                        className="form-input"
                        type="password"
                        id="pass"
                        value={pass}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setPass(e.target.value)
                        }
                    />
                </div>
                <View
                    className="form-group"
                    orientation="row"
                    style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <span
                        className={`opacity-fade ${
                            user.length > 0 && pass.length > 0 ? 'in' : ''
                        }`}
                        style={{ transitionDelay: '1.5s' }}
                    >
                        Let's do great things ❤️
                    </span>
                    <button
                        type="submit"
                        className={`btn btn-primary ${
                            loading ? 'loading' : ''
                        }`}
                        disabled={!user.length || !pass.length || loading}
                    >
                        log in
                    </button>
                </View>
            </form>
        </View>
    );
}
