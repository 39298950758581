import { createContext } from 'react';

export interface AuthedUser {
    user: string;
    pass: string;
}

export interface AuthCtxT {
    authedUser: AuthedUser | null;
    setAuthedUser: React.Dispatch<React.SetStateAction<AuthedUser | null>>;
}

export const AuthCtx = createContext<AuthCtxT>({
    authedUser: null,
    setAuthedUser: () => {},
});

const AUTHED_USER_KEY = 'authedUser';

export function readPersistedAuth(): AuthedUser | null {
    const stored = localStorage.getItem(AUTHED_USER_KEY);

    return stored ? JSON.parse(stored) : null;
}

export function persistAuth(authedUser: AuthedUser) {
    localStorage.setItem(AUTHED_USER_KEY, JSON.stringify(authedUser));
}

export function clearPersistedAuth() {
    localStorage.removeItem(AUTHED_USER_KEY);
}
