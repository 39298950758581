import React, { useCallback, useEffect, useRef } from 'react';
import { clearBodyLocks, lock, unlock } from 'tua-body-scroll-lock';

import { ViewportModal } from 'app/core/ViewportModal';
import { View } from 'app/core/layout';

interface ThoughtsModalProps {
    open: boolean;
    value: string;
    onChange: (val: string) => void;
    onCancel: () => void;
    onSave: () => void;
}

export function ThoughtsModal({
    open,
    value,
    onChange,
    onCancel,
    onSave,
}: ThoughtsModalProps) {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const onFocus = useCallback(() => {
        lock(inputRef.current);
    }, []);

    const onBlur = useCallback(() => {
        unlock(inputRef.current);
    }, []);

    useEffect(() => {
        return () => {
            clearBodyLocks();
        };
    }, []);

    if (!open) {
        return null;
    }

    return (
        <ViewportModal open={open}>
            <View
                style={{
                    background: '#fff',
                    padding: '8px 4px',
                }}
            >
                <div>
                    <View
                        orientation="row"
                        style={{ justifyContent: 'space-between' }}
                    >
                        <button className="btn btn-link" onClick={onCancel}>
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={onSave}
                            disabled={!value.trim().length}
                        >
                            Save
                        </button>
                    </View>
                </div>
                <textarea
                    autoFocus
                    className="form-input mt-2"
                    style={{
                        flex: 1,
                        resize: 'none',
                        border: 'none',
                        outline: 'none',
                        paddingBottom: 'env(safe-area-inset-bottom)',
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    ref={inputRef}
                    value={value}
                    onInput={(e) => onChange(e.currentTarget.value)}
                />
            </View>
        </ViewportModal>
    );
}
